import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "src/components/core/layout/layout"
import Img from "gatsby-image"
import flickrLogo from "../../assets/flickr.svg"
import instagramLogo from "../../assets/instagram.svg"
import SEO from 'src/components/seo/SEO';

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      data {
        photo {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        about {
          html
        }
      }
    }
    prismicContact {
      data {
        flickr {
          url
        }
        instagram {
          url
        }
        mail {
          url
        }
      }
    }
  }
`

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5rem;
  }
`
const AboutImage = styled.div`
  flex-basis: 45%;
  margin: 1rem 0;
`

const AboutTextWrapper = styled.div`
  flex-basis: 50%;
  margin: 1rem 0;
`

const AboutText = styled.div`
  margin-bottom: 2rem;
`

const MailWrapper = styled.div`
  font-style: italic;
`

const ContactWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
`
const ContactIconWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export default ({ data }) => {
  const photo = data.prismicAbout.data.photo ? data.prismicAbout.data.photo.fluid : null
  const aboutHTML = data.prismicAbout.data.about ? data.prismicAbout.data.about.html : null
  const instagramLink = data.prismicContact.data.instagram ? data.prismicContact.data.instagram.url : null
  const flickrLink = data.prismicContact.data.flickr ? data.prismicContact.data.flickr.url : null
  const mailUrl = data.prismicContact.data.mail ? data.prismicContact.data.mail.url : null
  const mailAddress = mailUrl ? mailUrl.slice(7) : null
  return (
      <>
      <SEO title="About" image={photo}/>
    <Layout>
      <AboutWrapper>
        <AboutImage>
          <Img fluid={photo} />
        </AboutImage>
        <AboutTextWrapper>
          <AboutText dangerouslySetInnerHTML={{ __html: aboutHTML }} />
          <MailWrapper>
            <a href={mailUrl}>{mailAddress}</a>
          </MailWrapper>
          <ContactWrapper>
            <a href={instagramLink}>
              <ContactIconWrapper>
                <img src={instagramLogo} />
              </ContactIconWrapper>
            </a>
          </ContactWrapper>
          <ContactWrapper>
            <a href={flickrLink}>
              <ContactIconWrapper>
                <img src={flickrLogo} />
              </ContactIconWrapper>
            </a>
          </ContactWrapper>
        </AboutTextWrapper>
      </AboutWrapper>
    </Layout>
    </>
  )
}
